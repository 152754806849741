import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import type { ResultsData, TestOutcome } from '@sh24/admin-api-js'
import RichTextDocumentContext from '../../contexts/rich-text-document-context'
import ResultCard from './result-card'
import resultsToCardContexts, { ResultsCardContext } from '../../utils/results-to-card-contexts'
import useTranslations from '../../utils/use-translations'
import useResultCards from '../../utils/use-result-cards'
import groupBy from '../../utils/group-by'
import replaceTranslationTokens from '../../utils/replace-translation-tokens'
import { useAppContext } from '../../contexts/app-context'
import fetchResultCards from '../../services/contentful/fetch-result-cards'
import ConsultationPanel from './consultation-panel'

const UpdatedAtContainer = styled.div`
  text-align: center;
`

const ResultCardWithContext = ({
  value,
  resultCardsLoaded,
  openByDefault = false,
}: {
  value: Record<string, ReactNode>,
  resultCardsLoaded: boolean,
  openByDefault: boolean,
}) => {
  const resultCards = useResultCards()
  const infection = value.infection as string
  const resultCard = resultCards[infection]

  if (!resultCard) {
    if (!resultCardsLoaded) return null

    const translations = useTranslations()
    return <p>{translations['account.results.noResultCard']}: {infection}</p>
  }

  return (
    <RichTextDocumentContext.Provider value={value}>
      <ResultCard resultCard={resultCard} openByDefault={openByDefault} />
    </RichTextDocumentContext.Provider>
  )
}

const formatUpdatedAtText = (date: Date, translations: Record<string, string>): string => (
  replaceTranslationTokens({
    translations,
    translationKey: 'results.updatedAt',
    values: [format(date, 'dd LLLL'), format(date, 'h:mmaaa')],
  })
)

const ResultsPanelOrderDisplay = ({
  orderId,
  results,
  openByDefault = false,
}: {
  orderId: string,
  results: ResultsData,
  openByDefault?: boolean,
}) => {
  const currentOrder = results.orders.find((order) => order.sh24Uid === orderId)
  const { setAppContext, appContext } = useAppContext()
  const resultCards = useResultCards()
  const [resultCardsState, setResultCardsState] = useState<'missing'|'loading'|'loaded'>('missing')
  const translations = useTranslations()
  const contexts = resultsToCardContexts(results, orderId)
  const groupedContexts = groupBy(
    contexts,
    (context) => context.updatedAt as number,
  )
  const sortedKeys = Array.from(groupedContexts.keys()).sort((a, b) => b - a)

  const customOutcomeOrder: Record<TestOutcome, number> = {
    positive: 1,
    reactive: 2,
    missing: 3,
    equivocal: 4,
    haemolysed: 4,
    insufficient: 4,
    invalid_sample: 4,
    lab_error: 4,
    not_processed: 4,
    negative: 5,
    numerical: 98,
    unknown: 99,
  }

  const cardContextSort = (a: ResultsCardContext, b: ResultsCardContext) => {
    // Sort outcomes according to custom order
    if (customOutcomeOrder[a.outcome] < customOutcomeOrder[b.outcome]) return -1
    if (customOutcomeOrder[a.outcome] > customOutcomeOrder[b.outcome]) return 1

    // Sort translated infections alphabetically
    const infectionTextA = translations[`replacementTokens.results.infection.${a.infection}`].toLowerCase()
    const infectionTextB = translations[`replacementTokens.results.infection.${b.infection}`].toLowerCase()
    if (infectionTextA.localeCompare(infectionTextB) < 0) return -1
    if (infectionTextA.localeCompare(infectionTextB) > 0) return 1

    // Sort translated sites alphabetically with null values at the end
    const siteA = a.site || null
    const siteB = b.site || null

    if (siteA !== null && siteB === null) return -1
    if (siteA === null && siteB !== null) return 1
    if (siteA === null && siteB === null) return 0

    const siteTextA = translations[`replacementTokens.results.site.${a.site}`].toLowerCase()
    const siteTextB = translations[`replacementTokens.results.site.${b.site}`].toLowerCase()
    if (siteTextA.localeCompare(siteTextB) < 0) return -1
    if (siteTextA.localeCompare(siteTextB) > 0) return 1

    return 0
  }

  const requiredCardTypes = Array.from(new Set<string>(contexts.map((context) => context.infection as string)))
  const missingCardTypes = requiredCardTypes.filter((type) => !resultCards[type])

  useEffect(() => {
    const setResultCard = async () => {
      setResultCardsState('loading')
      try {
        setAppContext(
          {
            ...appContext,
            resultCards: {
              ...appContext.resultCards,
              ...(await fetchResultCards({ preview: appContext.preview, types: missingCardTypes })),
            },
          },
        )
      } finally {
        setResultCardsState('loaded')
      }
    }

    if (missingCardTypes.length && resultCardsState === 'missing') {
      setResultCard()
    }
  }, [missingCardTypes])

  return (
    <div>
      {currentOrder?.followOn?.consultation && (
        <ConsultationPanel consultation={currentOrder.followOn.consultation} />
      )}
      {sortedKeys.map((key) => (
        <React.Fragment key={key}>
          <UpdatedAtContainer className="mt-md mb-md">
            <p>{formatUpdatedAtText(new Date(key), translations)}</p>
          </UpdatedAtContainer>
          {groupedContexts.get(key)?.sort(cardContextSort).map((value) => (
            <ResultCardWithContext
              key={`${key}-${value.infection}-${value.site}`}
              value={value}
              resultCardsLoaded={resultCardsState === 'loaded'}
              openByDefault={openByDefault}
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export default ResultsPanelOrderDisplay
