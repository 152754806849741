const fetchResultCards = async ({
  preview,
  types = [],
}: {
  preview: boolean,
  types?: string[],
}) => {
  const data = JSON.stringify({ preview, types })
  const result = await fetch('/api/result-card', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })

  return result.json()
}

export default fetchResultCards
