// Hopefully in future this will be built in to Javascript,
// but for now here is a simple implementation.
// https://github.com/tc39/proposal-array-grouping
function groupBy<Type, KeyType>(
  array: Array<Type>,
  callbackfn: (value: Type, index: number) => KeyType,
): Map<KeyType, Array<Type>> {
  const map = new Map<KeyType, Array<Type>>()
  array.forEach((element, index) => {
    const key = callbackfn(element, index)
    if (map.has(key)) {
      (map.get(key) as Type[]).push(element)
    } else {
      map.set(key, [element])
    }
  })
  return map
}

export default groupBy
