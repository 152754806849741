const replaceTranslationTokens = ({ translations, translationKey, values }) => {
  let text = translations[translationKey]

  if (!text) return null

  values.forEach((value, index) => {
    const regExp = new RegExp(`\\{${index + 1}\\}`, 'g')
    text = text.replace(regExp, value)
  })

  return text
}

export default replaceTranslationTokens
