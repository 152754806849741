const comparisons = {
  doesNotEqual: (a, b) => a !== b,
  doesNotInclude: (a, b) => !a?.includes?.(b),
  equals: (a, b) => a === b,
  greaterThan: (a, b) => a > b,
  greaterThanEqualTo: (a, b) => a >= b,
  hasExtraProducts: (a) => !!(a?.some?.((item) => item.isExtra)),
  hasPrimaryProducts: (a) => !!(a?.some?.((item) => item.isPrimary)),
  hasSecondaryProducts: (a) => !!(a?.some?.((item) => item.isSecondary)),
  includes: (a, b) => !!(a?.includes?.(b)),
  includesProduct: (a, b) => !!(a?.find?.((product) => product.identifier === b)),
  isNotSet: (a) => !(a === false ? '0' : a),
  isSet: (a) => !!(a === false ? '0' : a),
  lessThan: (a, b) => a < b,
  lessThanEqualTo: (a, b) => a <= b,
  isIncludedIn: (a, b) => b.toString().split(',').map((e) => e.trim()).includes(a),
}

export default comparisons
