import { ResultCard } from '@sh24/contentful-service'
import { useAppContext } from '../contexts/app-context'

type AppContextType = {
  appContext?: {
    resultCards?: Record<string, ResultCard>,
  },
}

const useResultCards = () => {
  const { appContext } = useAppContext() as AppContextType
  return appContext?.resultCards || {}
}

export default useResultCards
